<script setup>
import {
  OmnichannelChatSDK,
  isCustomerMessage,
} from '@microsoft/omnichannel-chat-sdk';
import { computed, onMounted, onUnmounted, onUpdated, ref, toRaw } from 'vue';
import {
  ChatBubbleOvalLeftIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
  MinusIcon,
} from '@heroicons/vue/24/solid';
import interact from 'interactjs';

import LoadingThingy from './components/LoadingThingy.vue';
import InternalSurveyForm from './components/InternalSurveyForm.vue';
import ChatFooter from './components/ChatFooter.vue';
import ChatEnd from './components/ChatEnd.vue';
import { departmentMapping, buildOOHMessage, addAgentAvailabilityToChannel, extractDataFromIDC, postChatUserActionsToKafkaTopic, postChatExceptionsToKafkaTopic } from './rules';
import { MESSAGES, UNAVAILABLE_REASON, CHATS_AVAILABLE_ON_PATHS, SESSION_END_INTERVAL, CHAT_CHANNEL, EVENTS, EXCEPTION_MESSAGE } from './constants';

import messsageSound from './assets/message-received.mp3';

defineProps({
  isInternal: {
    type: Boolean,
    default: false
  }
})
const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true, // Disable telemetry
  },
};

const messageNotification = new Audio(messsageSound);
const audioPlayEnabled = ref(false);
const userMuted = ref(false);
const isLoading = ref(true);
const closedExtra = ref(false);
const showChatWidget = ref(false);
const preChatQuestions = ref([]);
const waiting_to_start = ref(null);
const initializeError = ref(null);
const preChatSurveyResponses = ref({
});
const chatMessages = ref([]);
const isTyping = ref(false);
const messageToSend = ref('');
const sendingMessage = ref(false);
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailTranscript = ref('');
const confirmCloseChat = ref(false);
const confirmDecisionState = ref(false);
const showPostChatSurvey = ref(false);
const showQualtricsSurvey = ref(false);
const transcriptSentFailed = ref('');
const transcriptSending = ref(false);
const transcriptSent = ref(false);
const currentChannel = ref("");
const chatId = ref(null);
const labelToIdMap = ref({});
const customerName = ref(null);
const agentName = ref(null);
const surveyUrl = ref(null);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const url = import.meta.env.VITE_AGENT_AND_OOH_INFO_URL;
const sendMessageError = ref('');
const activatorPosition = ref(null);
const containerPosition = ref(null);
const storedPreChatResponse = ref({});
const showBubbleOnSpecialCondition = ref(null);
const offlineTimeout = ref(null);
const inactivityTimeout = ref('');
const convId = ref("");

onMounted(async () => {
  console.log('called onMounted');
  isLoading.value = false;
  const channelFromSession = JSON.parse(sessionStorage.getItem('MS365CurrentChannel'));
  if (channelFromSession) {
    await initializeChat(channelFromSession?.id || null, Boolean(channelFromSession?.id));
  }
  if (sessionStorage.getItem('timed-out') === "true") {
    if (localStorage.getItem("Authentication")) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    showChatWidget.value = true;
    confirmCloseChat.value = false;
    confirmDecisionState.value = false;
    showPostChatSurvey.value = true;
    endChat(false, true);
  }

  if (window.innerWidth >= 500) {
    activatorPosition.value = { left: (windowWidth.value - 100) + "px", top: (windowHeight.value - 110) + "px" };
    containerPosition.value = { left: (windowWidth.value - 496) + "px", top: (windowHeight.value - 650) + "px" };
  } else {
    // activatorPosition.value = { left: (windowWidth.value - 100) + "px", top: (windowHeight.value - 80) + "px" };
    activatorPosition.value = { bottom: "0px", right: "0px" };
    containerPosition.value = { top: "45px", left: "0px" };
  }
});

let chatSDK = null;

const closeChatWithConfirmation = async (endIfNotStarted) => {
  const { conversationDetails, conversationList } = await getConversationData();
  try {
    if (chatSDK && chatSDK?.chatToken?.chatId) {
      if (confirm(MESSAGES.END_CHAT_CONFIRMATION) === true) {
        try {
          await postChatUserActionsToKafkaTopic(
            EVENTS.END,
            CHAT_CHANNEL.INTERNAL,
            currentChannel.value,
            storedPreChatResponse.value,
            convId.value,
            conversationDetails,
            conversationList
          );
        } catch (e) {
          console.log(e);
        } finally {
          await chatSDK.endChat();
          cleanUp();
          clearSurvey();
        }
      } else {
        throw new Error('Chat already in progress');
      }
    } else if (endIfNotStarted && !chatSDK?.chatToken?.chatId) {
      cleanUp();
      showChatWidget.value = false;
    }
  }
  catch (e) {
    console.log(e);
    isLoading.value = false;
    if (endIfNotStarted && !chatSDK?.chatToken?.chatId) {
      cleanUp();
      showChatWidget.value = false;
    }
    return;
  }
};


const initializeChat = async (widgetId, hasExistingSession = false) => {
  showBubbleOnSpecialCondition.value = CHATS_AVAILABLE_ON_PATHS.includes(window.location.pathname) ? false : true;
  isLoading.value = true;
  if (!widgetId) {
    console.log('Widget Id is not provided');
    initializeError.value = MESSAGES.ERROR_INITIATE_CHAT;
    isLoading.value = false;
    return
  }
  await closeChatWithConfirmation();
  if (!hasExistingSession) {
    cleanUp();
  }
  const _url = url + '?' + new URLSearchParams({
    "app_id": widgetId,
  });
  const channelDetails = await fetch(_url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
  let { data, utcNow } = channelDetails;
  data = {
    "name": data.channelName,
    "id": data.channelAppId,
    utcNow,
    ...data
  }
  currentChannel.value = data;
  // Check for OOH First before agent availability
  const channelWithAvailablility = addAgentAvailabilityToChannel(data, utcNow, 0);
  if (channelWithAvailablility.isUnAvailable && channelWithAvailablility.reason === UNAVAILABLE_REASON.OOH) {
    console.log('Chat Initiated in OOH');
    const oohMessage = buildOOHMessage(data);
    initializeError.value = `${MESSAGES.OOH_MESSAGE} ${oohMessage}`;
    isLoading.value = false;
    showChatWidget.value = showBubbleOnSpecialCondition.value;
    return
  }
  // Agent Availability Check
  // if (channelWithAvailablility.isUnAvailable && channelWithAvailablility.reason === UNAVAILABLE_REASON.NO_AGENTS) {
  //   console.log(MESSAGES.AGENTS_NOT_AVAILABLE);
  //   initializeError.value = MESSAGES.AGENTS_NOT_AVAILABLE
  //   isLoading.value = false;
  //   showChatWidget.value = true;
  //   return
  // }
  initializeError.value = '';
  chatSDK = new OmnichannelChatSDK({
    ...omnichannelConfig,
    widgetId: widgetId
  }, chatSDKConfig);
  await chatSDK.initialize();
  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  const checkPreviousSession = sessionStorage.getItem('MS365ChatSession');
  if (checkPreviousSession) {
    try {
      const optionalParams = {};
      optionalParams.liveChatContext = JSON.parse(checkPreviousSession);
      await chatSDK.startChat(optionalParams);
      chatMessages.value = await chatSDK.getMessages();
      await initializeChatEvents();
      showChatWidget.value = showBubbleOnSpecialCondition.value;
      closedExtra.value = true;
      preChatSurveyResponses.value = JSON.parse(
        sessionStorage.getItem('MS365ChatPreChatSurvey')
      );
      for (const key in preChatSurveyResponses.value) {
        let parsedKey = JSON.parse(key).QuestionText;
        let parsedValue = "";
        if (preChatSurveyResponses.value[key].includes("Id")) {
          parsedValue = JSON.parse(preChatSurveyResponses.value[key]).Value;
        } else {
          parsedValue = preChatSurveyResponses.value[key];
        }
        storedPreChatResponse.value[parsedKey] = parsedValue;
      }
      const { conversationDetails } = await getConversationData();
      if (conversationDetails.conversationId) {
        convId.value = conversationDetails.conversationId;
        try {
          await postChatUserActionsToKafkaTopic(
            EVENTS.REFRESH,
            CHAT_CHANNEL.INTERNAL,
            currentChannel.value,
            storedPreChatResponse.value,
            convId.value,
            conversationDetails
          );
        } catch (e) {
          console.log(e);
        }
      }
      setTimeout(() => {
        document
          .getElementById('scrollContainer')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    } catch (e) {
      console.log(e);
      postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.RESTORE_SESSION);
      clearSessionStore();
    }
  }
  isLoading.value = false;
  showChatWidget.value = showBubbleOnSpecialCondition.value;
}

const clearSessionStore = () => {
  sessionStorage.removeItem('MS365ChatSession');
  sessionStorage.removeItem('MS365ChatPreChatSurvey');
  sessionStorage.removeItem('MS365CurrentChannel');
  sessionStorage.removeItem('timed-out');
}

onUnmounted(() => {
  console.log('called onUnmounted');
  clearSessionStore();
});

// Called when the chat widget is toggled eg: minimise of maximise
const toggleChat = async () => {
  initializeAudio();
  //console.log('Called Toggle Chat');
  if (confirmCloseChat.value) return;

  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    isLoading.value = true;
    closedExtra.value = true;
    isLoading.value = false;
    if (chatMessages.value.length > 0) {
      setTimeout(() => {
        document
          .getElementById('scrollContainer')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    }
  }
};

const initializeChatEvents = async () => {
  await chatSDK.onNewMessage((message) => {
    if (audioPlayEnabled.value) {
      messageNotification.play();
    }
    if (
      chatMessages.value.length === 1 &&
      chatMessages.value[0].id === '00000000-0000-0000-0000-000000000000'
    ) {
      chatMessages.value = [];
    }
    if (waiting_to_start.value !== null) {
      clearTimeout(waiting_to_start.value);
      waiting_to_start.value = null;
    }
    showChatWidget.value = true;
    if (
      chatMessages.value.length === 1 &&
      message.content === chatMessages.value[0].content
    ) {
      return;
    }
    chatMessages.value = [message, ...chatMessages.value];
    setTimeout(() => {
      let scrollContainer = document.getElementById('scrollContainer');
      scrollContainer?.scrollTo(0, scrollContainer.scrollHeight)?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    if (!isCustomerMessage(message)) {
      agentName.value = message.sender.displayName;
      chatId.value = chatSDK?.chatToken?.chatId;
    }
  });
  await chatSDK.onTypingEvent(() => {
    isTyping.value = true;
    setTimeout(() => {
      isTyping.value = false;
    }, 1000);
  });
  await chatSDK.onAgentEndSession(async () => {
    //alert("Agent ended the session. Please refresh the page to start a new session.")
    sendingMessage.value = true;
    const { conversationDetails, conversationList } = await getConversationData();
    try {
      surveyUrl.value = buildSurveyUrl(preChatSurveyResponses, chatId, agentName);
      await postChatUserActionsToKafkaTopic(EVENTS.END, CHAT_CHANNEL.INTERNAL, currentChannel.value, storedPreChatResponse.value, convId.value, conversationDetails, conversationList, "AGENT");
    } catch (e) {
      console.log(e);
    }
    clearSessionStore();
    endChat(true);
    sendingMessage.value = false;
  });
};

const checkAndSyncMessages = async () => {
  chatMessages.value = await chatSDK.getMessages();
  if (chatMessages.value.length === 0) {
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: 'Connecting...',
        sender: {
          displayName: '',
        },
        timestamp: new Date().toISOString(),
      },
    ];
    setTimeout(() => {
      document
        .getElementById('scrollContainer')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    waiting_to_start.value = setTimeout(async () => {
      chatMessages.value = await chatSDK.getMessages();
      if (chatMessages.value.length === 0) {
        chatMessages.value = [
          {
            id: '00000000-0000-0000-0000-000000000000',
            content: MESSAGES.WAITING_FOR_AGENT,
            sender: {
              displayName: '',
            },
            timestamp: new Date().toISOString(),
          },
        ];
      }
    }, 5000);
  }
};

const chatAction = (e) => {
  e.stopPropagation();
  console.warn("CHAT INITIATED");
  toggleChat();
}


// Start Chat on click of start button inside chat or on click of chat icon if in sales page
const startChat = async (preChatResponse, mapping) => {
  for (const key in preChatResponse) {
    let parsedKey = JSON.parse(key).QuestionText;
    let parsedValue = "";
    if (preChatResponse[key].includes("Id")) {
      parsedValue = JSON.parse(preChatResponse[key]).Value;
    } else {
      parsedValue = preChatResponse[key];
    }
    storedPreChatResponse.value[parsedKey] = parsedValue;
  }

  // Reset transcriptSent to false
  transcriptSent.value = false;
  labelToIdMap.value = mapping
  customerName.value = preChatResponse[mapping["Name"]] ?? "";
  preChatResponse = {
    Type: 'InputSubmit',
    ...preChatResponse,
  };
  try {
    initializeAudio(true);
    isLoading.value = true;
    const customChatContext = {
      Source: { value: window?.location?.href || 'Unknown', isDisplayable: true },
    };
    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customChatContext,
      sendDefaultInitContext: true,
    });
    console.warn("CHAT STARTED");
    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem('MS365ChatSession', JSON.stringify(chatContext));
    sessionStorage.setItem(
      'MS365ChatPreChatSurvey',
      JSON.stringify(preChatSurveyResponses.value)
    );
    sessionStorage.setItem('MS365CurrentChannel', JSON.stringify(currentChannel.value));
    await initializeChatEvents();
    await sendMessage(JSON.stringify(storedPreChatResponse.value));
    await checkAndSyncMessages();
    setTimeout(async () => {
      const { conversationDetails } = await getConversationData();
      if (conversationDetails.conversationId) {
        convId.value = conversationDetails.conversationId;
        try {
          await postChatUserActionsToKafkaTopic(
            EVENTS.START,
            CHAT_CHANNEL.INTERNAL,
            currentChannel.value,
            storedPreChatResponse.value,
            convId.value,
            conversationDetails
          );
        } catch (e) {
          console.log(e);
        }
      }
    }, 10000);
    isLoading.value = false;
  } catch (e) {
    // alert('Something went wrong. Please try again.');
    isLoading.value = false;
    if (e.message === 'ConversationInitializationFailure') {
      try {
        await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.CONVERSATION_INITIALIZATION);
      } catch (e) {
        console.log(e);
      }
      initializeError.value = MESSAGES.ERROR_INITIATE_CHAT;
    } else if (e.message === 'WidgetUseOutsideOperatingHour') {
      try {
        await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.WIDGET_OUTSIDE_OPERATING_HOURS);
      } catch (e) {
        console.log(e);
      }
      initializeError.value = MESSAGES.AGENTS_NOT_AVAILABLE;
    }
  }
};

const initializeAudio = (unmute = false) => {
  if (!audioPlayEnabled.value) {
    messageNotification.play().then(() => {
      messageNotification.pause();
      messageNotification.currentTime = 0;
      audioPlayEnabled.value = true;
    });
  }
  if (unmute) {
    userMuted.value = false;
  }
  if (!audioPlayEnabled.value) {
    userMuted.value = true;
  }
};

const sendMessage = async (preChatQuestionsData) => {
  sendingMessage.value = true;
  if (preChatQuestionsData) {
    await chatSDK.sendMessage({
      id: '00000000-0000-0000-0000-000000000000',
      content: preChatQuestionsData,
      sender: {
        displayName: 'Customer',
      },
      timestamp: new Date().toISOString(),
    })
    sendingMessage.value = false;
    return;
  }
  if (messageToSend.value.length === 0) {
    return;
  }
  initializeAudio(false);
  try {
    sendMessageError.value = '';
    await chatSDK.sendMessage({
      content: messageToSend.value,
    });
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: messageToSend.value,
        sender: {
          displayName: 'Customer',
        },
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    messageToSend.value = '';
    setTimeout(() => {
      let scrollContainer = document.getElementById('scrollContainer');
      scrollContainer?.scrollTo(0, scrollContainer.scrollHeight)
      document.getElementById('chatInputBox')?.focus();
    }, 100);
  } catch (e) {
    sendMessageError.value = MESSAGES.SEND_MESSAGE_FAILURE;
    try {
      postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.SEND_MESSAGE);
    } catch (e) {
      console.log(e);
    }
    console.log(e);
    sendingMessage.value = false;
  }
};

const customerTyping = async (event) => {
  if (event.key === 'Enter' && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

// Called when close button is clicked when in form screen
const endChat = async (confirmed = false, onInactive = false) => {
  //console.log('called endChatWindow', confirmed);
  if (!confirmed) {
    if (window.location.pathname.includes("/idc") || localStorage.getItem("Authentication")) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    surveyUrl.value = buildSurveyUrl(preChatSurveyResponses, chatId, agentName, labelToIdMap);
  }
  if (confirmed) {
    preChatSurveyResponses.value = {};
    clearSessionStore();
  }
  if (onInactive) {
    inactivityTimeout.value = MESSAGES.INACTIVITY_TIMEOUT;
  }
  if (chatMessages.value.length === 0) {
    showChatWidget.value = false;
    return;
  }
  confirmCloseChat.value = true;
  confirmDecisionState.value = true;
  if (confirmed) {
    if (window.location.pathname.includes("/idc") || localStorage.getItem("Authentication")) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    showPostChatSurvey.value = true;
  }
};

const cancelEndChat = () => {
  //console.log('called cancelEndChat');
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
};

const cleanFieldsAndValidations = () => {
  //console.log('called cleanFieldsAndValidations');
  preChatSurveyResponses.value = {};
};


const cleanUp = () => {
  //console.log('called cleanUp');
  cleanFieldsAndValidations();
  clearSessionStore();
  showChatWidget.value = false;
  closedExtra.value = false;
  chatMessages.value = [];
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
  showPostChatSurvey.value = false;
  chatId.value = null;
  inactivityTimeout.value = '';
  agentName.value = null;
  surveyUrl.value = null;
  offlineTimeout.value = null;
  labelToIdMap.value = {};
  customerName.value = null;
  currentChannel.value = {};
  clearSurvey();
};

const emailKeyUp = () => {
  transcriptSentFailed.value = '';
};

const getConversationData = async () => {
  if (chatSDK === null) {
    return { conversationDetails: {}, conversationList: [] }
  };
  const conversationDetails = await chatSDK.getConversationDetails();
  const conversationList = chatMessages.value.map((message) => {
    if (message.sender.displayName === 'Customer') {
      return {
        CUSTOMER: message.content,
      };
    } else {
      return {
        AGENT: message.content,
      };
    }
  })
  return {
    conversationDetails,
    conversationList
  };
}

const closeChatWindow = async (endChat = true) => {
  const { conversationDetails, conversationList } = await getConversationData();
  try {
    if (endChat && !transcriptSent.value && chatSDK?.chatToken?.chatId) {
      if (convId.value !== "") {
        try {
          await postChatUserActionsToKafkaTopic(
            EVENTS.END,
            CHAT_CHANNEL.INTERNAL,
            currentChannel.value,
            storedPreChatResponse.value,
            convId.value,
            conversationDetails,
            conversationList
          );
        } catch (e) {
          console.log(e);
        } finally {
          await chatSDK.endChat();
          confirmCloseChat.value = true;
          showPostChatSurvey.value = true;
        }
      }
      transcriptSent.value = false;
    }
  }
  catch (e) {
    console.log(e);
    try {
      await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.CLOSE_CHAT_WINDOW, conversationDetails);
    } catch (e) {
      console.log(e);
    }
  }
}

const startNewChat = async (_endChat = true) => {
  // console.log('called startNewChat'. endChat);
  let _currentChannelId = currentChannel?.value?.id;
  // Call end chat only when transcript is not sent, else end chat is called in requestEmailTranscript
  // if (!transcriptSent.value) {
  //   transcriptSent.value = false;
  //   try {
  //     await postChatUserActionsToKafkaTopic(
  //       EVENTS.END,
  //       CHAT_CHANNEL.INTERNAL,
  //       currentChannel.value,
  //       storedPreChatResponse.value,
  //       convId.value,
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     await chatSDK.endChat();
  //     cleanUp();
  //   }
  // }
  cleanUp();
  await initializeChat(_currentChannelId);
};

const downloadFile = (fileMetadata) => {
  chatSDK
    .downloadFileAttachment(toRaw(fileMetadata))
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = fileMetadata.name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      // Remove link from body
      document.body.removeChild(link);
    })
    .catch(async (e) => {
      try {
        await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.DOWNLOAD_ATTACHMENT);
      } catch (e) {
        console.log(e);
      }
      console.log(e);
    });
};

const processUpload = async (evt) => {
  const file = evt.target.files[0];
  try {
    sendingMessage.value = true;
    const resp = await chatSDK?.uploadFileAttachment(file);
    chatMessages.value = [
      {
        id: resp.id,
        ...resp,
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    evt.target.value = null;
  } catch (e) {
    console.log(e);
    try {
      postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.UPLOAD_ATTACHMENT);
    } catch (e) {
      console.log(e);
    }
  }
};

const requestEmailTranscript = async () => {
  const { conversationDetails, conversationList } = await getConversationData();
  if (!emailPattern.test(emailTranscript.value)) {
    transcriptSentFailed.value = 'Please enter a valid email.';
    return;
  }
  try {
    transcriptSending.value = true;
    transcriptSentFailed.value = '';
    await chatSDK.emailLiveChatTranscript({
      emailAddress: emailTranscript.value,
      attachmentMessage: 'Your Chat Transcript',
    });
    transcriptSending.value = false;
    transcriptSent.value = true;
    setTimeout(() => {
      transcriptSent.value = false;
    }, 10000);
    try {
      await postChatUserActionsToKafkaTopic(
        EVENTS.TRANSCRIPTS,
        CHAT_CHANNEL.INTERNAL,
        currentChannel.value,
        storedPreChatResponse.value,
        convId.value,
        conversationDetails
      );
    } catch (e) {
      console.log(e);
    } finally {
      try {
        await postChatUserActionsToKafkaTopic(EVENTS.END, CHAT_CHANNEL.INTERNAL, currentChannel.value, storedPreChatResponse.value, convId.value, conversationDetails, conversationList);
      } catch (e) {
        console.log(e);
      } finally {
        await chatSDK.endChat();
        confirmCloseChat.value = true;
        showPostChatSurvey.value = true;
      }
    }
  } catch (e) {
    console.log(e);
    try {
      await postChatExceptionsToKafkaTopic(EVENTS.ERROR, CHAT_CHANNEL.INTERNAL, storedPreChatResponse.value, currentChannel.value, e, convId.value, EXCEPTION_MESSAGE.EMAIL_TRANSCRIPT);
    } catch (e) {
      console.log(e);
    }
    transcriptSent.value = false;
    transcriptSending.value = false;
    transcriptSentFailed.value = 'Unable to email transcript.';
  }
};

//Set this once chatsdk is initialized
window.initiateChat = async (secretKey, widgetId) => {
  //console.log(secretKey, widgetId)
  if (secretKey === 'CAB724D7E74F5') {
    await initializeChat(widgetId);
  }
};

window.destroyChat = async (secretKey) => {
  const { conversationDetails, conversationList } = await getConversationData();
  if (secretKey === 'CAB724D7E74F5') {
    if (chatSDK && chatSDK?.chatToken?.chatId) {
      try {
        await postChatUserActionsToKafkaTopic(
          EVENTS.END,
          CHAT_CHANNEL.INTERNAL,
          currentChannel.value,
          storedPreChatResponse.value,
          convId.value,
          conversationDetails,
          conversationList
        );
      } catch (e) {
        console.log(e);
      } finally {
        await chatSDK.endChat();
      }
    }
    cleanUp();
  }
};

window.toggleChat = toggleChat;

const buildSurveyUrl = (preChatSurveyResponses, chatId, agentName, labelToIdMap) => {
  labelToIdMap = labelToIdMap.value || {};
  const channelId = preChatSurveyResponses.value.channel_id;
  const agentNameFormatted = agentName.value ? `${channelId} -${agentName.value} ` : "";
  const chatIdFinal = chatId.value ? chatId.value : "";
  const customerName = preChatSurveyResponses.value[labelToIdMap["Name"] || ""] || "";
  const customerEmail = preChatSurveyResponses.value[labelToIdMap["Email"] || ""] || "";
  const customerPhone = preChatSurveyResponses.value[labelToIdMap["Phone Number"] || ""] || "";
  const location = departmentMapping.find((dept) => dept.dept === channelId)?.deptId || channelId || "";
  return `https://ziplyfiber.sjc1.qualtrics.com/jfe/form/SV_eqXCFSpkf31O68d?location=${location}&TriggeredAt=${new Date().toISOString()}&CustomerName=${customerName}&CustomerEmail=${customerEmail}&CustomerPhone=${customerPhone}&agent_id=${agentNameFormatted}&ChatId=${chatIdFinal}`;

}

const clearSurvey = () => {
  var qualtricsSurvey = document.getElementById('qualtricsSurvey');
  if (qualtricsSurvey) {
    qualtricsSurvey.style.display = 'none';
    if (qualtricsSurvey.childNodes.length > 0) {
      qualtricsSurvey.removeChild(qualtricsSurvey.childNodes[0]);
    }
  }
  showQualtricsSurvey.value = false;
};

const adjustWidget = () => {
  if (window.innerWidth >= 500) {
    interact("#chatActivator").draggable({
      listeners: {
        move: dragMoveListener,
      },
    });
  }

  function dragMoveListener(event) {
    let target = event.target;
    // Get the position of chatActivator
    let x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // Constrain chatActivator position within the viewport
    x = Math.min(Math.max(x, 0), window.innerWidth - target.offsetWidth - 20);
    y = Math.min(Math.max(y, 0), window.innerHeight - target.offsetHeight - 20);

    // Calculate the position for chatContainer
    let chatContainerX, chatContainerY;

    // Determine the horizontal position of chatContainer based on chatActivator
    if (x > window.innerWidth / 2) {
      chatContainerX = x - 400; // Open chat container to the left of chatActivator
    } else {
      chatContainerX = x + target.offsetWidth + 20; // Open chat container to the right of chatActivator
    }

    // Determine the vertical position of chatContainer based on chatActivator
    if (y > window.innerHeight / 2) {
      chatContainerY = y - 544; // Adjust the height of chatContainer as needed
    } else {
      chatContainerY = y; // No adjustment needed
    }

    // Constrain chatContainer position within the viewport
    chatContainerX = Math.min(Math.max(chatContainerX, 0), window.innerWidth - 400); // Adjusted to our chatContainer width
    chatContainerY = Math.min(Math.max(chatContainerY, 0), window.innerHeight - 544); // Adjusted to our chatContainer height

    // Set the position of chatContainer
    const chatContainer = document.getElementById("chatContainers");
    chatContainer.style.position = "fixed";
    containerPosition.value = { left: chatContainerX + "px", top: chatContainerY + "px" };

    // Update the position attributes of chatActivator
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);

    // Translate the chatActivator
    activatorPosition.value = { left: x + "px", top: y + "px" };
  }
}
adjustWidget();

window.addEventListener("resize", () => {
  if (window.innerWidth >= 500) {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
    activatorPosition.value = { left: (windowWidth.value - 100) + "px", top: (windowHeight.value - 110) + "px" };
    containerPosition.value = { left: (windowWidth.value - 496) + "px", top: (windowHeight.value - 650) + "px" };
    adjustWidget();
  }
  else {
    interact("#chatActivator").unset();
    activatorPosition.value = { bottom: "0px", right: "0px" };
    containerPosition.value = { top: "45px", left: "0px" };
  }
});

window.ononline = async () => {
  if (sessionStorage.getItem('timed-out') === "true") {
    if (localStorage.getItem("Authentication")) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    showChatWidget.value = true;
    confirmCloseChat.value = false;
    confirmDecisionState.value = false;
    showPostChatSurvey.value = true;
    endChat(false, true);
  } else if (chatSDK?.chatToken?.chatId) {
    chatMessages.value = await chatSDK.getMessages();
    inactivityTimeout.value = '';
    sendingMessage.value = false;
    sendMessageError.value = '';
    clearTimeout(offlineTimeout.value);
    offlineTimeout.value = null;
  }
};

window.onoffline = async () => {
  sendingMessage.value = true;
  sendMessageError.value = MESSAGES.NO_INTERNET;
  offlineTimeout.value = setTimeout(() => {
    sessionStorage.setItem('timed-out', 'true');
  }, SESSION_END_INTERVAL);
};

</script>

<template>
  <div id="chatWrapper" class="chatWrapper" v-if="currentChannel?.id">
    <!-- Chat Icon Start-->
    <div id="chatActivator" :data-x="(windowWidth - 96) + 'px'" :data-y="(windowHeight - 96) + 'px'"
      class="!zf-z-[1501] zf-w-max zf-flex zf-gap-4 zf-justify-end zf-fixed" :style="activatorPosition">
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark !zf-bg-ziply-blue md:zf-w-24 md:zf-h-24 md:zf-rounded-[4px]"
        v-if="!showChatWidget" :disaled="isLoading" @click="chatAction($event)">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <div v-if="!showChatWidget" id="chatBubble">
              <ChatBubbleOvalLeftIcon class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100" />
            </div>
            <p class=" zf-text-3.5 md:zf-text-xl md:zf-flex zf-hidden zf-m-0 zf-text-white">
              Chat
            </p>
          </div>
        </div>
      </div>
      <div
        class="!zf-flex zf-items-center zf-justify-center !zf-px-4 !zf-h-fit !zf-py-2 zf-relative md:zf-flex zf-w-max"
        id="closeBubble" v-if="!isLoading && !showChatWidget && showBubbleOnSpecialCondition">
        <button class="!zf-fill-white !zf-bg-ziply-blue-dark zf-rounded-full zf-absolute -zf-left-2 -zf-top-2 !zf-p-1"
          @click="closeChatWithConfirmation(true)" id="closeWithConfirmation">
          <XMarkIcon class="!zf-w-3 !zf-h-3 zf-fill-white" />
        </button>
      </div>
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark zf-circle-button md:zf-w-16 md:zf-h-16 !zf-bg-[#000050]"
        v-if="showChatWidget" :disaled="isLoading">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <div v-if="showChatWidget" id="bars3Bottom">
              <Bars3BottomRightIcon class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Icon End -->
    <div id="chatContainers" class="zf-w-max !zf-z-[1500] zf-fixed" :style="containerPosition">
      <div
        class="zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 !zf-h-[544px] sm:zf-w-[400px] !sm:zf-h-[546px] !xs:zf-w-full !zf-border !zf-border-white"
        v-if="showChatWidget">
        <div
          class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t">
          <div v-if="!confirmDecisionState" @click="toggleChat()" id="minimizeIcon">
            <MinusIcon class="!zf-h-6 !zf-w-6 !zf-mr-3 !zf-fill-gray-100 zf-cursor-pointer" />
          </div>
          <p class="!zf-text-center !zf-w-full !zf-text-lg zf-text-white zf-m-0">
            {{ currentChannel?.name ? `Chat with ${currentChannel?.name} ` : "Chat" }}
          </p>
          <div v-if="!confirmCloseChat" @click="endChat()" id="endChatButton">
            <XMarkIcon class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-end-chat-button" />
          </div>
          <div v-if="showQualtricsSurvey || confirmCloseChat" v-show="showPostChatSurvey" @click="cleanUp()"
            id="closeChatButton">
            <XMarkIcon class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-close-chat-button" />
          </div>
        </div>
        <!-- Chat Body-->
        <div class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] zf-overflow-hidden">
          <!-- Welcome message and form -->
          <div v-if="chatMessages?.length === 0" class="!zf-p-6 !zf-h-full !zf-overflow-scroll zf-no-scrollbar">
            <p class="!zf-mb-2 !zf-text-justify" v-if="!isLoading && !initializeError">
              To proceed with this chat, please complete the following and click <strong>Start Chat</strong>.
            </p>
            <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="initializeError">
              <span class="zf-whitespace-pre-wrap">
                {{ initializeError }}
              </span>
            </p>
            <!-- <p v-if="isLoading">Processing...</p> -->
            <LoadingThingy v-if="isLoading" class="!zf-fill-blue-500" />
            <InternalSurveyForm :start-chat="startChat" v-if="!initializeError && !isLoading"
              :pre-chat-survey-responses="preChatSurveyResponses" :pre-chat-questions="preChatQuestions"
              :is-loading="isLoading" :is-internal="isInternal" />
          </div>
          <template v-else>
            <div class="!zf-flex  zf-flex-col !zf-gap-2 !zf-h-full" v-if="!confirmCloseChat">
              <!-- Chat messages -->
              <div id="scrollContainer"
                class="!zf-flex !zf-flex-col-reverse !zf-h-[90%] !zf-px-4 !zf-py-2 !zf-overflow-scroll zf-no-scrollbar">
                <div v-for="message in chatMessages" :key="message.id" class="!zf-flex !zf-flex-col !zf-gap-1 ">
                  <div class="!zf-flex !zf-flex-col !zf-items-start" v-if="!isCustomerMessage(message)">
                    <div class="!zf-text-ziply-blue !zf-flex !zf-justify-center !zf-items-center !zf-mb-2">
                      <p class="!zf-text-sm !zf-text-ziply-blue !zf-font-normal">
                        {{ agentName || message.sender.displayName }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2" v-if="message.content !== '' &&
                      typeof message.content !== 'object'
                    ">
                      <p class="!zf-text-gray-800 !zf-text-sm !zf-text-left">
                        {{ message.content }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                      v-if="message.fileMetadata !== undefined">
                      <button class="!zf-text-blue-600 !zf-text-sm zf-underline"
                        @click="downloadFile(Object.freeze(message.fileMetadata))">
                        {{ message.fileMetadata.name }}
                      </button>
                    </div>
                    <div v-if="message.timestamp !== ''">
                      <p class="zf-italic zf-text-xs zf-text-gray-500">
                        {{ new Date(message.timestamp).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                  <div class="!zf-flex !zf-flex-col !zf-items-end" v-else>
                    <div
                      class="!zf-text-slate-800 !zf-rounded-full !zf-flex !zf-justify-center !zf-items-center !zf-mb-2">
                      <p class="!zf-text-sm !zf-font-normal !zf-text-gray-600">
                        {{ customerName || preChatSurveyResponses.name }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2"
                      v-if="message.content !== '' && message.content.includes('Agent name')">
                      <div class=" !zf-text-gray-800 !zf-text-sm !zf-text-right ">
                        <div v-for=" ( value, key ) in JSON.parse(message.content) " :key="key">
                          <table class="zf-w-full zf-border zf-rounded-lg">
                            <tr class="zf-w-full zf-border zf-rounded-lg">
                              <th class="zf-w-2/4 zf-text-center zf-p-1">{{ key }}</th>
                              <td class="zf-w-2/4 zf-text-center zf-p-1">{{ value }}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2" v-if="message.content !== '' &&
                      typeof message.content !== 'object' && message.content.includes('Agent name') === false
                    ">
                      <p class="!zf-text-gray-800 !zf-text-sm !zf-text-right zf-break-words">
                        {{ message.content }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                      v-if="message.fileMetadata !== undefined">
                      <button class="!zf-text-blue-600 !zf-text-sm !zf-underline"
                        @click="downloadFile(Object.freeze(message.fileMetadata))">
                        {{ message.fileMetadata.name }}
                      </button>
                    </div>
                    <div v-if="message.timestamp !== ''">
                      <p class="zf-italic zf-text-xs zf-text-gray-500">
                        {{ new Date(message.timestamp).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Chat Messages End-->
              <!-- Chat Footer-->
              <ChatFooter :is-typing="isTyping" :send-message-error="sendMessageError" :message-to-send="messageToSend"
                @update:message-to-send="newValue => messageToSend = newValue" :process-upload="processUpload"
                :sending-message="sendingMessage" :send-message="sendMessage" :customer-typing="customerTyping" />
            </div>
            <div div class="!zf-w-full !zf-h-full !zf-bg-white" v-if="confirmCloseChat">
              <!-- Close Confirmation-->
              <!-- <div v-if=" !showPostChatSurvey" class="zf-text-center  zf-pt-36 zf-pb-8">
              <p class="zf-text-white ">Are you sure you want to end the chat?</p>
              <div class="zf-flex zf-flex-col zf-gap-5 mt-4 zf-justify-center zf-px-16 zf-mt-4">
                <button
                  class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                  @click="endChat(true)">
                  End Chat
                </button>
                <button
                  class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                  @click="cancelEndChat()">
                  Cancel
                </button>
              </div>
            </div> -->
              <div v-if="!showPostChatSurvey"
                class="zf-text-center zf-flex zf-flex-col zf-items-center zf-justify-center zf-h-full zf-gap-4 zf-px-6">
                <p>Would you like to receive an email transcript of your conversation before you end chat?</p>
                <div class="zf-flex zf-flex-col zf-gap-4 zf-w-full">
                  <p v-if="inactivityTimeout" class="zf-text-red-500 zf-text-center zf-text-sm">{{ inactivityTimeout }}
                  </p>
                  <form method="post" @submit.prevent="requestEmailTranscript()" class="zf-flex zf-flex-col zf-gap-4">
                    <input type="email" :value="emailTranscript" @keyup.prevent="emailKeyUp()"
                      placeholder="Enter your email address" @input="emailTranscript = $event.target.value" :class="{
                        '!zf-border !zf-text-red-700 !zf-border-red-700':
                          transcriptSentFailed !== '',
                      }" id="email_chat_transcript"
                      class="!zf-w-full  !zf-rounded-full !zf-bg-gray-100 !zf-px-4 !zf-py-2" required />
                    <p class="!zf-text-red-500 zf-text-center zf-text-sm" v-if="transcriptSentFailed !== ''">
                      {{ transcriptSentFailed }}
                    </p>
                    <button type="submit"
                      class="!zf-px-6 !zf-py-2 !zf-w-full !zf-rounded-full   zf-bg-ziply-blue !zf-text-md hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6 disabled:zf-bg-gray-500 disabled:zf-text-gray-500 disabled:!zf-cursor-not-allowed"
                      :class="{ 'animate-pulse': transcriptSending }" :disabled="transcriptSending && !transcriptSent">
                      <template v-if="!transcriptSending">End chat and Email the Transcript</template>
                      <template v-else>Sending...</template>
                    </button>
                  </form>


                  <div class="zf-border zf-border-ziply-blue zf-my-4"></div>
                  <button
                    class="!zf-px-6 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue !zf-text-md hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                    @click="closeChatWindow(true)">
                    End the Chat
                  </button>
                  <button
                    class="!zf-px-6 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue !zf-text-md hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                    @click="cancelEndChat()">
                    Continue Chatting
                  </button>
                </div>
              </div>
              <template v-else>
                <ChatEnd :email-transcript=emailTranscript :show-qualtrics-survey="showQualtricsSurvey"
                  @update:show-qualtrics-survey="newValue => showQualtricsSurvey = newValue"
                  :inactivity-timeout="inactivityTimeout" :transcript-sent="transcriptSent"
                  :start-new-chat="startNewChat" :survey-url="surveyUrl" :is-internal=true :internal=true
                  :conv-id="convId" :pre-chat-survey-responses="storedPreChatResponse" />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>