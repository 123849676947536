<script setup>
import { defineProps, ref, defineEmits } from 'vue'
import { postChatExceptionsToKafkaTopic, postChatUserActionsToKafkaTopic } from '../rules';
import { CHAT_CHANNEL, EVENTS, EXCEPTION_MESSAGE } from '../constants';
const props = defineProps({
    showQualtricsSurvey: Boolean,
    transcriptSent: Boolean,
    emailTranscript: String,
    startNewChat: Function,
    surveyUrl: String,
    isInternal: Boolean,
    inactivityTimeout: String,
    salesLocation: Boolean,
    salesAgentsAvailable: Boolean,
    channelOptions: Object,
    preChatSurveyResponses: Object,
    convId: String,
    external: Boolean,
    internal: Boolean,
})

const emit = defineEmits(['update:showQualtricsSurvey']);

const emailTranscript = ref(props.emailTranscript ? props.emailTranscript : "");

const initiateSurvey = async () => {
    const { preChatSurveyResponses, channelOptions, convId } = props;
    try {
        emit('update:showQualtricsSurvey', true);
        const qualtricsSurvey = document.getElementById('qualtricsSurvey');
        const iframe = document.createElement('iframe');
        iframe.src = props.surveyUrl;
        iframe.width = '100%';
        iframe.height = '100%';
        iframe.className = '!w-full !h-full';
        qualtricsSurvey.appendChild(iframe);
        qualtricsSurvey.style.display = 'block';
        try {
            await postChatUserActionsToKafkaTopic(
                EVENTS.SURVEY,
                props.external ? CHAT_CHANNEL.EXTERNAL : props.internal ? CHAT_CHANNEL.INTERNAL : CHAT_CHANNEL.ENTERPRISE,
                props.channelOptions,
                props.preChatSurveyResponses,
                props.convId,
            );
        } catch (e) {
            console.log(e);
        }
    } catch (e) {
        await postChatExceptionsToKafkaTopic(EVENTS.ERROR, props.external ? CHAT_CHANNEL.EXTERNAL : props.internal ? CHAT_CHANNEL.INTERNAL : CHAT_CHANNEL.ENTERPRISE, preChatSurveyResponses, channelOptions, e, convId, EXCEPTION_MESSAGE.INITIATE_SURVEY)
        console.log(e);
    }
};


const canTriggerNewChat = ref(true);

if (!props.isInternal && props.salesLocation && !props.salesAgentsAvailable) {
    canTriggerNewChat.value = false;
}

</script>
<template>
    <div class="zf-flex zf-flex-col zf-justify-center zf-h-full zf-px-6" v-if="!showQualtricsSurvey">

        <div class=" !zf-mb-3 zf-text-center zf-text-[#008600]" v-if="transcriptSent">
            <p>Transcript sent to : <span class="zf-font-bold">{{ emailTranscript }}</span></p>
        </div>
        <p v-if="inactivityTimeout" class="zf-text-red-500 zf-text-center zf-text-sm">{{ inactivityTimeout }}
        </p>
        <p class="zf-block !zf-mb-3 zf-text-center" v-if="!isInternal">
            We appreciate hearing feedback and invite you to take a
            brief survey.
        </p>
        <div class="zf-btn-wrapper">
            <button
                class="!zf-px-2 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                v-if="!isInternal" @click="initiateSurvey()">
                BEGIN SURVEY
            </button>
            <button
                class="!zf-px-2 !zf-mt-4 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                :disabled="!canTriggerNewChat" :title="!canTriggerNewChat === 0 ? 'No agents available' : ''" :class="{
                    'zf-opacity-50 zf-cursor-not-allowed': !canTriggerNewChat,
                }" @click="startNewChat()">
                START NEW CHAT
            </button>
        </div>

    </div>
    <div class="!zf-w-full !zf-h-full" id="qualtricsSurvey" ref="qualtricsSurvey" style="display: none"></div>
</template>