<script setup>

import VueMarkdown from 'vue-markdown-render'
import * as AdaptiveCards from "adaptivecards";
import MarkItExternalLink from 'markdown-it-external-link';
import { computed, defineProps, onMounted, ref } from 'vue'
import { isJsonMessage } from '../rules';

const plugins = [MarkItExternalLink]
const options = {
    linkify: true
}

const props = defineProps({
    message: String | Object,
    nextMessage: Object | null
})

const emit = defineEmits(['update:messageOnClick'])

const adaptiveBody = ref(null)

const cardAlreadyFilled = computed(() => props.nextMessage != null && props.nextMessage.content != null && isJsonMessage(props.nextMessage.content));

const renderAdaptiveCard = (content) => {
    if (content) {
        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        if (cardAlreadyFilled.value) {
            const val = JSON.parse(props.nextMessage.content).value;
            content.body.forEach((element, idx) => {
                if (element.id in val) {
                    content.body[idx].value = val[element.id]
                };
            });
        };
        adaptiveCard.parse(content);
        adaptiveCard.onExecuteAction = async (action) => {
            if (cardAlreadyFilled.value) {
                alert("You cannot submit your response now.");
                return;
            }
            const msg = JSON.stringify({ "value": action._processedData });
            emit('update:messageOnClick', msg, { "microsoft.azure.communication.chat.bot.contenttype": "azurebotservice.adaptivecard" });
            adaptiveBody.value.querySelectorAll("select").forEach((element) => {
                element.disabled = true;
            });
            adaptiveBody.value.querySelectorAll("input").forEach((element) => {
                element.disabled = true;
            });
            adaptiveBody.value.querySelectorAll("button").forEach((element) => {
                element.disabled = true;
            });
        }
        const htmlthing = adaptiveCard.render(); // Renders as HTML element
        adaptiveBody.value.appendChild(htmlthing);
        if (cardAlreadyFilled.value) {
            adaptiveBody.value.querySelectorAll("select").forEach((element) => {
                element.disabled = true;
            });
            adaptiveBody.value.querySelectorAll("input").forEach((element) => {
                element.disabled = true;
            });
            adaptiveBody.value.querySelectorAll("button").forEach((element) => {
                element.disabled = true;
            });
        }
    }
}

let _message = { ...props.message };
try {
    let _content = _message?.content
    if (typeof _content === 'string' && _content.indexOf("attachments") !== -1) {
        _content = JSON.parse(_message?.content)
        if (_content.attachments?.length > 0 && _content.attachments[0].contentType === 'application/vnd.microsoft.card.adaptive') {
            _message.content = _content.attachments[0].content
            _message.type = 'AdaptiveCard'
        } else {
            _message.content = {
                question: _content?.text,
                options: _content?.suggestedActions?.actions ?? []
            }
            _message.type = 'MCQ'
        }
    } else if (typeof _content === 'string' && _content.indexOf("attachments") === -1) {
        _message.type = 'STRING'
    }
}
catch (err) {
    console.log(err)
}

onMounted(() => {
    if (_message.type === 'AdaptiveCard') {
        renderAdaptiveCard(_message.content)
    }
})

</script>

<template>
    <div class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2" v-if="_message.type === 'STRING'">
        <p class="!zf-text-gray-800 !zf-text-sm !zf-text-left zf-message">
            <vue-markdown :source="_message.content" :options="options" :plugins="plugins"></vue-markdown>
        </p>
    </div>
    <div class="!zf-bg-gray-100 !zf-w-full zf-rounded-lg !zf-px-4 !zf-py-2" v-if="_message.type === 'MCQ'">
        <p class="chetan !zf-text-gray-800 !zf-text-sm !zf-text-left !zf-w-full">
            <vue-markdown :source="_message.content.question" :options="options" :plugins="plugins"
                class="link-para"></vue-markdown>
        </p>
        <div class="!mb-3 block">
            <button id="select-buttons" v-for="item in _message.content.options" type="button"
                @click="$emit('update:messageOnClick', item.text)"
                class="!zf-px-3 !zf-py-1 !zf-m-1 zf-rounded-full zf-border-ziply-blue !zf-border zf-text-ziply-blue !zf-text-sm">
                {{ item.title }}
            </button>
        </div>
    </div>
    <div class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2 !zf-w-full" v-if="_message.type === 'AdaptiveCard'">
        <div ref="adaptiveBody"></div>
    </div>
</template>