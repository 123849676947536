import moment, { utc } from "moment-timezone";
import {
  SUCCESS,
  UNAVAILABLE_REASON,
  APP_NAME,
  FAILURE,
  EVENTS,
  CHAT_CHANNEL,
} from "./constants.js";
import { v4 as uuidv4 } from "uuid";
import { UAParser } from "ua-parser-js";

export const departmentMapping = [
  {
    dept: "Sales",
    deptId: "146294496063627846",
  },
  {
    dept: "Customer Service",
    deptId: "146210741310792512",
  },
  {
    dept: "Technical Service",
    deptId: "146210740591606181",
  },
  {
    dept: "Collections",
    deptId: "4326258904527478102",
  },
];

export const getIdOfQuestionByName = (preChatQuestions, name) => {
  const id = preChatQuestions.body.find(
    (item) =>
      (item.type === "Input.Text" || item.type === "Input.ChoiceSet") &&
      JSON.parse(item.id).Name === name
  )?.id;
  return id;
};

export const addAgentAvailabilityToChannel = (
  _item = {},
  utcTimeNow = "",
  offset = 1
) => {
  let item = { ..._item };
  let message = "";
  let agentAvailKey =
    "agent_available" in item ? "agent_available" : "agentAvailable";
  if (item[agentAvailKey] === undefined) {
    agentAvailKey = "agentAvailability";
  }
  const operationHoursKey =
    "operation_hours" in item ? "operation_hours" : "operatingHours";
  const conditionOne = item[agentAvailKey]
    ? item[agentAvailKey] === false
    : true;
  let conditionTwo =
    item[operationHoursKey] === null || item[operationHoursKey] === "";
  if (!conditionTwo) {
    const operatingHours = item[operationHoursKey][0] ?? {};
    const { Start, End } = operatingHours;
    if (Start && End) {
      let currentTime = moment().tz("US/Pacific");
      if (utcTimeNow) {
        currentTime = moment(utcTimeNow)
          .add(offset / (1000 * 60), "minutes")
          .tz("US/Pacific");
      }
      const isAvailable = moment(currentTime).isBetween(
        moment(Start),
        moment(End)
      );
      conditionTwo = !isAvailable;
    } else {
      conditionTwo = true;
    }
  }
  if (conditionTwo) {
    message =
      item?.operation_hours_human ||
      item?.operationHoursHuman ||
      "Please contact us during business hours.";
  } else if (conditionOne) {
    message = "No Agents Available, Try after sometime.";
  }
  item.message = message;
  item.isUnAvailable = conditionOne || conditionTwo || false;
  item.reason = conditionTwo
    ? UNAVAILABLE_REASON.OOH
    : UNAVAILABLE_REASON.NO_AGENTS;
  return item;
};

export const extractDataFromMyAccount = () => {
  const localStorage = window.localStorage;
  let userInfo = null;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (e) {
    console.log(e);
  }
  return userInfo;
};

export const buildOOHMessage = (data) => {
  let humanMessage = "";
  if (data && data?.availability && data?.availability.length > 0) {
    humanMessage = "\nAvailability Timings:";
    data.availability.forEach((item) => {
      humanMessage = `${humanMessage}\n${item.from} - ${item.to} from ${item.time}`;
    });
  }
  return humanMessage;
};

export const extractDataFromSalesPages = (params) => {
  const sessionStorage = window.sessionStorage;
  let data = {
    custom_url: sessionStorage.getItem("currentRoute"),
    custom_address: "",
    custom_page_name: params.pageName,
    custom_service_type: "",
    custom_product_selected: "",
    custom_orignating_source: "",
    custom_SAM_Control_NO: "",
    initial_question: "",
    custom_addOnList: "",
    custom_promotion: "",
    name: "",
    email: "",
    phone: "",
    custom_appointment_selected: "",
  };

  if (params.address && sessionStorage.getItem("address")) {
    data.custom_address = sessionStorage.getItem("address");
    // if (sessionStorage.getItem('unitno')) {
    //   data.custom_address = data.custom_address + '#' + sessionStorage.getItem('unitno');
    // }
  }
  if (params.serviceType && sessionStorage.getItem("productData")) {
    const productData = JSON.parse(sessionStorage.getItem("productData"));
    if (
      productData.body &&
      productData.body.content &&
      productData.body.content.response &&
      productData.body.content.response.products &&
      productData.body.content.response.products.length > 0
    ) {
      data.custom_service_type =
        productData.body.content.response.products[0].product_type;
    }
  }
  if (params.customerInfo && sessionStorage.getItem("customerInfo")) {
    const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
    data.name = customerInfo
      ? customerInfo.firstName + " " + customerInfo.lastName
      : "";
    data.email = customerInfo ? customerInfo.email : "";
    data.phone = customerInfo ? customerInfo.cellphone : "";
  }
  if (params.productSelected && sessionStorage.getItem("productSelected")) {
    const productSelected = JSON.parse(
      sessionStorage.getItem("productSelected")
    );
    data.custom_product_selected =
      "Product_name : " +
      productSelected.productName +
      " , " +
      "Product_speed : " +
      productSelected.eligibility_criteria +
      " , " +
      "Product_type :" +
      productSelected.product_type +
      " , " +
      "Product_line : " +
      productSelected.product_line +
      " , " +
      "Service_type : " +
      productSelected.service_type +
      " , " +
      "Product_SKU : " +
      productSelected.sku;
  }
  if (params.addOnList) {
    const addOnList = [];
    const cartDetails = JSON.parse(sessionStorage.getItem("cartResponse")).cart;
    if (
      cartDetails &&
      cartDetails.ItemsList &&
      cartDetails.ItemsList.length > 0
    ) {
      cartDetails.ItemsList.forEach((element) => {
        if (element.Items && Object.keys(element.Items).length > 0) {
          Object.values(element.Items).forEach((value) => {
            if (
              value.ProductCatalogInformation &&
              value.ProductCatalogInformation.Price &&
              value.ProductCatalogInformation.Price.Quantity &&
              value.ProductCatalogInformation.Price.Quantity.Amount &&
              value.ProductCatalogInformation.Price.Quantity.Amount > 0
            ) {
              addOnList.push(JSON.stringify(value));
            }
          });
        }
      });
    }
    // const phoneAddon = sessionStorage.getItem('phoneAddon');
    // const rokuAddon = sessionStorage.getItem('rokuAddon');
    // const wifiAddon = sessionStorage.getItem('wifiAddon');
    // if (installAddon) {
    //   addOnList.push({ 'InstallAddon': JSON.parse(installAddon) });
    // }
    // if (phoneAddon) {
    //   addOnList.push({ 'phoneAddon': JSON.parse(phoneAddon) });
    // }
    // if (rokuAddon) {
    //   addOnList.push({ 'rokuAddon': JSON.parse(rokuAddon) });
    // }
    // if (wifiAddon) {
    //   addOnList.push({ 'wifiAddon': JSON.parse(wifiAddon) });
    // }

    data.custom_addOnList = addOnList.toString();
  }
  if (
    params.selAppointment &&
    sessionStorage.getItem("subscriptionCustomerData")
  ) {
    const appointment_selected = JSON.parse(
      sessionStorage.getItem("subscriptionCustomerData")
    );
    data.custom_appointment_selected =
      appointment_selected.scheduleDate +
      " " +
      appointment_selected.scheduleTime;
  }
  if (params.selAppointment && sessionStorage.getItem("selAppointment")) {
    const appSelected = JSON.parse(sessionStorage.getItem("selAppointment"));
    data.custom_appointment_selected =
      appSelected.date + " " + appSelected.timing;
  }
  data.custom_orignating_source = "SALES_BUY_FLOW";
  data.custom_SAM_Control_NO = "";
  data.initial_question = "";
  data.custom_promotion = "";
  return data;
};

export const getBuildDataForSalesPage = () => {
  const path = window.location.pathname;
  if (path.includes("/servicecheckresults")) {
    data = extractDataFromSalesPages({
      pageName: "Product selection page",
      address: true,
      serviceType: true,
    });
    // data.custom_page_name = 'Product selection page';
  } else if (path.includes("/secservicecheck")) {
    data = extractDataFromSalesPages({
      pageName: "Secondary address selection page",
      address: true,
      serviceType: true,
    });
    // data.custom_page_name = 'Product selection page';
  } else if (path.includes("/ordercustomize")) {
    data = extractDataFromSalesPages({
      pageName: "Add-Ons configuration page",
      address: true,
      serviceType: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Add-Ons configuration page';
  } else if (path.includes("/customerinfo")) {
    data = extractDataFromSalesPages({
      pageName: "Customer Information page",
      address: true,
      serviceType: true,
      addOnList: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Customer Information page';
  } else if (
    path.includes("/ordersubmit") ||
    path.includes("/servicerequest")
  ) {
    data = extractDataFromSalesPages({
      pageName: "Schedule appointment page",
      address: true,
      serviceType: true,
      addOnList: true,
      customerInfo: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Schedule page';
  } else if (path.includes("/orderconfirm")) {
    data = extractDataFromSalesPages({
      pageName: "Confirmation Page",
      address: true,
      serviceType: true,
      addOnList: true,
      customerInfo: true,
      selAppointment: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Schedule page';
  }
  // else if (path.includes('/servicerequest')) {
  //   data = extractDataFromSalesPages({'pageName': 'Schedule page', 'address': true, 'serviceType': true, 'addOnList': true, 'customerInfo': true, 'selAppointment': true, 'productSelected': true});
  //   // data.custom_page_name = 'Schedule page';
  // }
  else if (path.includes("/sales")) {
    data = extractDataFromSalesPages({ pageName: "Address search page" });
    // data.custom_page_name = 'Address search page';
  }
  return data;
};

export const extractDataFromIDC = () => {
  const localStorage = window.localStorage;
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem("Authentication"));
  } catch (e) {
    console.log(e);
  }
  return user;
};

const detectBrowserAndDevice = () => {
  const userAgent = window.navigator.userAgent;
  const isMobile = /Mobi/i.test(userAgent);
  const screenWidth = window.screen.width;
  let device = "";
  let browser = "";
  // Detect Device
  if (isMobile) {
    device = "Mobile";
  } else if (screenWidth < 768) {
    device = "Tablet";
  } else {
    device = "Desktop/Laptop";
  }
  // Detect Browser
  const parser = new UAParser();
  const userAgentInfo = parser.getResult();
  browser = userAgentInfo.browser.name;
  return { browser, device };
};

const splitName = (name) => {
  const names = name.split(" ");
  const firstName = names[0] || "";
  const lastName = names.slice(1).join(" ") || "";
  return { firstName, lastName };
};

const getTopicByEvent = (event) => {
  let topic = "";
  let eventAction = "";
  let eventStatus = "";
  switch (event) {
    case EVENTS.START:
      topic = "chat.useractions";
      eventAction = "START_CHAT";
      eventStatus = SUCCESS;
      break;
    case EVENTS.END:
      topic = "chat.useractions";
      eventAction = "END_CHAT";
      eventStatus = SUCCESS;
      break;
    case EVENTS.REFRESH:
      topic = "chat.useractions";
      eventAction = "REFRESH";
      eventStatus = SUCCESS;
      break;
    case EVENTS.ERROR:
      topic = "chat.exceptions";
      eventAction = "CHAT_ERROR";
      eventStatus = FAILURE;
      break;
    case EVENTS.TRANSCRIPTS:
      topic = "chat.useractions";
      eventAction = "CHAT_TRANSCRIPTS";
      eventStatus = SUCCESS;
      break;
    case EVENTS.SURVEY:
      topic = "chat.useractions";
      eventAction = "CHAT_SURVEY";
      eventStatus = SUCCESS;
      break;
    case EVENTS.REGAINED_CONNECTIVITY:
      topic = "chat.useractions";
      eventAction = "REGAINED_CONNECTIVITY";
      eventStatus = SUCCESS;
      break;
    default:
      topic = "chat.useractions";
      eventAction = "UNKNOWN";
      eventStatus = FAILURE;
      break;
  }
  return { topic, eventAction, eventStatus };
};

function extractFieldAgentInfo(preChatResponse) {
  const preChatAgentName = preChatResponse["Agent name, CRIS/CORP ID"];
  const phone = preChatResponse["Phone number"];
  const email = preChatResponse["Email"];
  const nameParts = preChatAgentName.split(", ");
  const firstName = nameParts[0].split(" ")[0];
  const lastName = nameParts[0].split(" ")[1];
  const corpID = nameParts[1];
  return {
    firstName,
    lastName,
    phone,
    email,
    corpID,
  };
}

export const postChatUserActionsToKafkaTopic = async (
  event,
  chatType,
  channelOptions = {},
  preChatSurveyResponses,
  conversationId,
  conversationDetails = { message: "Default value" },
  allMessages = [],
  triggeredBy = "NON_AGENT"
) => {
  const { browser, device } = detectBrowserAndDevice();
  const url = import.meta.env.VITE_KAFKA_CUSTOMER_EVENTS;
  const { queues, utc_now } = channelOptions;
  const source = window.location.href;
  const currentChannel = preChatSurveyResponses.channel_id
    ? preChatSurveyResponses.channel_id
    : "Enterprise";
  const metaData =
    chatType === "ENTERPRISE" || chatType === "INTERNAL"
      ? { current_channel: channelOptions }
      : { queues_response: queues, server_utc_time: utc_now };
  const { firstName, lastName, phone, email, corpID } =
    preChatSurveyResponses.name
      ? splitName(preChatSurveyResponses.name)
      : Object.keys(preChatSurveyResponses).length === 0
      ? {}
      : extractFieldAgentInfo(preChatSurveyResponses);
  const { topic, eventAction, eventStatus } = getTopicByEvent(event);
  if (chatType === CHAT_CHANNEL.EXTERNAL || chatType === "ENTERPRISE") {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        topic: topic,
        messages: [
          {
            key: uuidv4(),
            value: {
              conversationDetails,
              current_channel: currentChannel,
              customer_usi: preChatSurveyResponses.usi,
              customer_phone: preChatSurveyResponses.phone,
              customer_email: preChatSurveyResponses.email,
              customer_first_name: firstName,
              customer_last_name: lastName,
              event_triggered_from: APP_NAME,
              event_triggered_by: APP_NAME,
              event_trigger_type: triggeredBy,
              event_created_at: moment().toISOString(),
              event_final_status: eventStatus,
              event_action: eventAction,
              chat_type: chatType,
              conversation_id: conversationId,
              platform: device,
              browserUsed: browser,
              conversationData: allMessages,
              originSource: source,
              meta_data: metaData,
            },
          },
        ],
      }),
    });
  } else if (chatType === CHAT_CHANNEL.INTERNAL) {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        topic: "chat.fieldagentactions",
        messages: [
          {
            key: uuidv4(),
            value: {
              agent_first_name: firstName,
              agent_last_name: lastName,
              agent_phone: phone,
              agent_email: email,
              agent_corpID: corpID,
              event_triggered_from: APP_NAME,
              event_triggered_by: APP_NAME,
              event_trigger_type: triggeredBy,
              event_created_at: moment().toISOString(),
              event_final_status: eventStatus,
              event_action: eventAction,
              chat_type: chatType,
              conversation_id: conversationId,
              conversationDetails,
              conversationData: allMessages,
              platform: device,
              browserUsed: browser,
              originSource: source,
              meta_data: {
                current_channel: channelOptions,
              },
            },
          },
        ],
      }),
    });
  }
};

export const postChatExceptionsToKafkaTopic = async (
  event,
  chatType,
  preChatSurveyResponses = {},
  channelOptions = {},
  exception,
  conversationId,
  message,
  conversationDetails = { message: "Default value" }
) => {
  const { browser, device } = detectBrowserAndDevice();
  const url = import.meta.env.VITE_KAFKA_CUSTOMER_EVENTS;
  const { queues, utc_now } = channelOptions;
  const source = window.location.href;
  const { firstName, lastName, phone, email, corpID } =
    preChatSurveyResponses.name
      ? splitName(preChatSurveyResponses.name)
      : Object.keys(preChatSurveyResponses).length === 0
      ? {}
      : extractFieldAgentInfo(preChatSurveyResponses);
  const { topic, eventAction, eventStatus } = getTopicByEvent(event);
  const metaData =
    chatType === "INTERNAL" || "ENTERPRISE"
      ? { current_channel: channelOptions }
      : { queues_response: queues, server_utc_time: utc_now };
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      topic: topic,
      messages: [
        {
          key: uuidv4(),
          value: {
            [chatType === "INTERNAL"
              ? "agent_first_name"
              : "customer_first_name"]: firstName,
            [chatType === "INTERNAL"
              ? "agent_last_name"
              : "customer_last_name"]: lastName,
            [chatType === "INTERNAL" ? "agent_phone" : "customer_phone"]: phone,
            [chatType === "INTERNAL" ? "agent_email" : "customer_email"]: email,
            [chatType === "INTERNAL" ? "agent_corpID" : "customer_corpID"]:
              corpID,
            [chatType === "INTERNAL" ? "agent_usi" : "customer_usi"]:
              preChatSurveyResponses.usi,
            event_triggered_from: APP_NAME,
            event_triggered_by: APP_NAME,
            event_trigger_type: "NON_AGENT",
            event_created_at: moment().toISOString(),
            event_final_status: eventStatus,
            event_action: eventAction,
            chat_type: chatType,
            conversation_id: conversationId,
            platform: device,
            browserUsed: browser,
            exception_message: exception.message,
            exception_stack: exception.stack,
            failureReason: message,
            conversationDetails,
            originSource: source,
            meta_data: metaData,
          },
        },
      ],
    }),
  });
};

export const isJsonMessage = (msg) => {
  try {
    const val = JSON.parse(msg);

    return "value" in val;
  } catch (e) {
    return false;
  }
};

export const printJsonMessage = (msg) => {
  try {
    const val = JSON.parse(msg).value;

    let toRet = "";

    for (let key in val) {
      if (!key.startsWith("action")) {
        toRet += `${key} : ${val[key]}<br>`;
      }
    }

    return toRet;
  } catch (e) {
    console.log(e);

    return null;
  }
};
